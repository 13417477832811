<template>
  <v-container>
    <v-row>
      <v-col class="mt-15">

      </v-col>
    </v-row>
  </v-container>
</template>
<script>

export default {
  name: 'AccountView',
  props: {},
  data: () => ({
    showError: false,
    error: null,
    loading: false,
    details: {}
  }),
  created() {
    // fetch the data when the view is created and the data is
    // already being observed
    console.log("AccountView::created");
    this.fetchData()
  },
  watch: {
    // call again the method if the route changes
    '$route': 'fetchData'
  },
  methods: {
    fetchData() {
      console.log("AccountView::fetchData");
      this.error = this.post = null
      this.loading = true
      let self = this;
      this.$http.get(`${process.env.VUE_APP_ENDPOINT}/admin/account`)
          .then(function (response) {
            self.items = response.data.records;
            self.pageInfo = response.data.pageInfo;
          })
          .catch(function (error) {
            if (error.response.status === 500) {
              self.error = "Da ist was schief gelaufen...";
            } else {
              self.error = error.response;
            }
            self.showError = true
          })
          .finally(() => this.loading = false)
      ;
    }
  }
}
</script>
